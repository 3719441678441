import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import moment from 'moment';

let now = null;

export default function Archive ({data}) {
	if (data && data.allMarkdownRemark && data.allMarkdownRemark.edges) {
		const posts = data.allMarkdownRemark.edges;

		return (
			<Layout>
				<SEO title="Archiv" />
				<section id="articles">
					{posts.map((post) => {
						const { node } = post;
						const { fields, html } = node;
						let { title, image, date, type, youtubeid } = node.frontmatter;
						let newDate = false;
						if (now === null) {
							now = moment(date);
							newDate = true;
						}

						if (!moment(date).isSame(now, 'month')) {
							now = moment(date);
							newDate = true;
						}

						return (
							<React.Fragment>
								{newDate && <h2>{now.clone().startOf('month').format('MMMM YYYY')}</h2>}
								<Link to={fields.slug} itemProp="url">
									<span itemProp="name">{title}</span>
								</Link>
								<br/>
							</React.Fragment>
						)
					})}
				</section>
			</Layout>
		);
	}

	return (<span>Archiv</span>)
}

export const pageQuery = graphql`
	query archiveQuery($skip:Int, $limit:Int) {
		allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: $limit, skip: $skip) {
			edges {
				node {
					id
					frontmatter {
						date
						title
					}
					fields {
						slug
					}
				}
			}
		}
	}
`;
